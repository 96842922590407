'use strict';



// settings

v.page = {

    console: false,

    current: {},
    names: [],
    roomID: null,

    bezier: {
        on: true,
        name: 'easeOutQuart'
    }

};
window.page = v.page;





// load & transition

v.addLoad(function(){
    v.document().classList.remove("v-none-transition");
}.bind(this));





/* Preloader */

page.preloader = new v.Preloader({
    console: page.console,
    links: true,
    animation: v.mobile() || (!v.mobile() & !v.checkPage("home"))? 500 : 0,
    animationInner: v.mobile() ? 25 : 1500
});
page.preloader = page.preloader.init();

page.preloader.add({
    do: function(){

        page.preloader.changeProp({
            animation: 500
        });

    }.bind(this)
});





/* Menu */

let menuClose = document.querySelector(".v-menu__close"),
    menuTelephone = document.querySelector(".v-menu__telephone"),
    menuLanguages = document.querySelector(".v-menu__languages"),
    menuLanguagesTitle = document.querySelector(".v-menu__languages-title"),
    menuLinks = document.querySelectorAll(".v-menu__links li"),
    menuSocial = document.querySelector(".v-menu__social"),
    menuSocialTitle = document.querySelector(".v-menu__social-title");



/* Social */

let menuSocialShown = false;
if(menuSocialTitle !== null){

    menuSocialTitle.addEventListener("click", function(){
        if(v.vw()[1] > 550){
            return;
        }
        if(!menuSocialShown){
            $(menuSocial).stop().slideDown(250);
            menuSocialShown = true;
        }
        else{
            $(menuSocial).stop().slideUp(250);
            menuSocialShown = false;
        }
    }.bind(this));

    window.addEventListener("click", function(e){
        if(v.vw()[1] > 550){
            return;
        }
        if(!v.childOf(e.target, menuSocialTitle) & !v.childOf(e.target, menuSocial)){
            if(menuSocialShown){
                $(menuSocial).stop().slideUp(250);
                menuSocialShown = false;
            }
        }
    }.bind(this));

}



/* Languages */

let menuLanguagesShown = false;
if(menuLanguagesShown !== null){

    menuLanguagesTitle.addEventListener("click", function(){
        if(v.vw()[1] > 550){
            return;
        }
        if(!menuLanguagesShown){
            $(menuLanguages).stop().slideDown(250);
            menuLanguagesShown = true;
        }
        else{
            $(menuLanguages).stop().slideUp(250);
            menuLanguagesShown = false;
        }
    }.bind(this));

    window.addEventListener("click", function(e){
        if(v.vw()[1] > 550){
            return;
        }
        if(!v.childOf(e.target, menuLanguagesTitle) & !v.childOf(e.target, menuLanguages)){
            if(menuLanguagesShown){
                $(menuLanguages).stop().slideUp(250);
                menuLanguagesShown = false;
            }
        }
    }.bind(this));

}



/* Menu */

let menuAnimationInner;

function menuAnimationSet(){

    menuAnimationInner = {
        on: true,
        duration: ((menuLinks.length - 1) * 50) + 700 - 350 
    };

}
menuAnimationSet();

page.menu = new v.Menu({
    console: page.console,
    animationButton: 1150,
    animation: 1000,
    animationInner: menuAnimationInner
});
page.menu = page.menu.init();

let menuOverlay = document.querySelector("#v-menu .v-menu__overlay");
if(menuOverlay !== null){
    menuOverlay.addEventListener("click", page.menu.hide.bind(this));
}

// menu close

if(menuClose !== null){
    menuClose.addEventListener("click", function(e){
        e.preventDefault();
        page.menu.hide();
    }.bind(this));
}

// on show

page.menu.add({
    target: 'show',
    do: function(){

        let timeout = 500;
        if(v.mobile()){
            timeout = 25;
        }

        setTimeout(() => {

            menuClose.classList.add("show");
            menuTelephone.classList.add("show");

        }, timeout);

        setTimeout(() => {

            for(let i = menuLinks.length - 1, a = 0; i >= 0; i--, a++){
                setTimeout(function(link){
                    link.classList.add("show")
                }.bind(this, menuLinks[i]), a * 50);
            }

        }, timeout + 250);

        setTimeout(() => {

            menuLanguages.classList.add("show");
            menuLanguagesTitle.classList.add("show");
            menuSocial.classList.add("show");
            menuSocialTitle.classList.add("show");

        }, timeout + 450);

    }.bind(this)
});

// on hide

page.menu.add({
    target: 'hide',
    do: function(){

        menuClose.classList.remove("show");
        menuTelephone.classList.remove("show");

        menuLanguages.classList.remove("show");
        menuLanguagesTitle.classList.remove("show");
        menuSocial.classList.remove("show");
        menuSocialTitle.classList.remove("show");

        for(let i = 0; i < menuLinks.length; i++){
            setTimeout(function(link){
                link.classList.remove("show")
            }.bind(this, menuLinks[i]), i * 50);
        }

    }.bind(this)
});